/*--------------STYLE--------------*/
.App {
  background: #E8DBD3;
}

.center {
  text-align: center;
}


.row::after {
    content: "";
    clear: both;
    display: table;
}

.row {
  display: flex;
}

h1 {
  font-family: 'Ribeye Marrow', cursive;
  color: #498467;
}

h2 {
  font-family: 'Oxygen', sans-serif;
}

h3 {
  font-family: 'Dosis', sans-serif;
}

h5 {
  font-family: 'Oxygen', sans-serif;
}

.black {
  color: black;
}

.redacted {
  color: black;
  background-color: black;
}

.grayText {
  color: gray;
}

.imgMed {
  width: 100%;
}

.width50 {
  width: 50%;
}

.imgThird {
  width: 100%;
  margin-top: 5%;
}

.col {
  margin-right: 2%;
  margin-left: 2%;
  width: 60%;
}


/*--------------LINKS--------------*/
a:link {
    color: #592941;
    text-decoration: underline;
}

a:visited {
    color: #592941;
    text-decoration: none;
}

a:hover {
    color: #498467;
    background: #B2D3A8;
    text-decoration: underline;
}

a:active {
    color: black;
    text-decoration: underline;
}

a.noStyle:hover {
    color: black;
    background: none;
    text-decoration: none;
}

/*--------------HEADER--------------*/
.header {
  max-width: 100%;
}

.navBox {
    background: #EDE5A6;
    border: 3px solid #498467;
}

.navText {
    font-family: 'Ribeye Marrow', cursive;
    font-size: 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email {
  width: 25px;
}

/*--------------HOME--------------*/
.homeBody {
  width: 80%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
}

.sidebar {
  width: 20%;
  background: #52b788;
  padding: 0% 2%;
  border: 3px solid #EDE5A6;
}

.sidebar h3 {
  font-family: 'Dosis', sans-serif;
}

.imgContainer {
  width: 40%;
  padding: 2%;
}

/*--------------ARTICLE--------------*/
.article {
  width: 80%;
  margin-left: 5%;
  margin-right: 5%;
}

/*--------------ABOUT--------------*/
.about {
  padding: 10px;
  margin-left: 100px;
  margin-right: 100px;
}

/*--------------FOOTER--------------*/
footer {
  text-align: center;
  color: #52b788;
  background: #592941;
  border: 3px solid #B2D3A8;
}
